<template>
    <div class="modal-wrapper">
        <div class="overlay"></div>
        <div class="modal-box">
            <div class="title">{{ $Lang.getTitle(title) }}</div>
            <div class="message">{{ $Lang.getTitle(message) }}</div>
            <div class="control">
                <template v-if="type === 'ok-cancel'">
                    <appButton :value="$Lang.get('ok')" @click="ok" />
                    <appButton :value="$Lang.get('cancel')" @click="cancel" />
                </template>
                <template v-if="type === 'confirm-cancel'">
                    <appButton :value="$Lang.get('confirm')" @click="confirm" />
                    <appButton :value="$Lang.get('cancel')" @click="cancel" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import appButton from './elements/button'
export default {
    components:{
        appButton
    },
    props: {
        title: { type:String, default:'' },
        message: { type:String, default:'' },
        type: { type:String, default:'ok-cancel' },
        data: null
    },
    emits:[ "ok", "cancel", "confirm" ],
    methods: {
        ok () {
            this.$emit('ok', this.data)
        },
        confirm () {
            this.$emit('confirm', this.data)
        },
        cancel () {
            this.$emit('cancel', this.data)
        }
    }
}
</script>

<style scoped lang="scss">
.modal-wrapper {
    position:fixed;
    top:0px;
    left:0px;
    z-index:10000;
    width:100vw;
    height:100vh;

    .overlay {
        width:100vw;
        height:100vh;
        background:rgba(0,0,0,0.5);
        position:fixed;
        top:0px;
        left:0px;
    }

    .modal-box {
        position:fixed;
        top:calc((100vh - 200px) / 2);
        left:calc((100vw - 30vw) / 2);
        width:30vw;
        color:var(--modal-text-color);
        border-radius:8px;
        overflow:hidden;
        box-shadow:0px 0px 10px rgba(0,0,0,0.3);

        .title {
            background:var(--modal-title-background-color);
            color:var(--modal-title-text-color);
            height:30px;
            line-height:30px;
            padding:0px 20px;
            border-radius:8px 8px 0px 0px;
            box-sizing:border-box;
        }

        .message {
            background:var(--modal-background-color);
            min-height:100px;
            padding:20px;
            box-sizing:border-box;
        }

        .control {
            background:var(--modal-background-color);
            height:60px;
            width:100%;
            text-align:center;
            
            input {
                min-width:inherit; width:30%;
                margin-right:20px;
                &:last-child { margin-right:0px; }
            }
        }
    }
}
@media only screen and (max-width:1000px) {
    .modal-wrapper {
        .modal-box {
            left:calc((100vw - 50vw) / 2);
            width:50vw;
        }
    }
}

@media only screen and (max-width:768px) {
    .modal-wrapper {
        .modal-box {
            top:calc((100vh - 25vh) / 2);
            left:0px;
            width:100vw;
            height:25vh;
            border-radius:0px;
            background:#ffffff;

            .title {
                border-radius:0px;
            }

            .control {
                position:absolute;
                bottom:0px;
            }
        }
    }
}
</style>